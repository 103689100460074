var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      !_vm.loading && _vm.user
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "user-panel", attrs: { span: 8 } },
                [
                  _c("h3", [_vm._v("External User Profile")]),
                  _c("user-profile", {
                    attrs: {
                      "show-account-email": "",
                      admin: true,
                      user: _vm.user,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "plan-panel", attrs: { span: 16 } },
                [
                  _c("h3", [_vm._v("Managed Users")]),
                  !_vm.loadingManaged &&
                  _vm.managedUsers &&
                  _vm.managedUsers.length
                    ? [
                        _c("el-input", {
                          attrs: {
                            "suffix-icon": _vm.managedFilter
                              ? ""
                              : "el-icon-search",
                            clearable: "",
                          },
                          model: {
                            value: _vm.managedFilter,
                            callback: function ($$v) {
                              _vm.managedFilter = $$v
                            },
                            expression: "managedFilter",
                          },
                        }),
                        _vm.managedFilter &&
                        _vm.filtered_managed_users.length === 0
                          ? _c("div", { staticClass: "no-results" }, [
                              _vm._v(" No matches found "),
                            ])
                          : _vm._e(),
                        _vm._l(_vm.filtered_managed_users, function (usr) {
                          return _c("user-card", {
                            key: usr.name,
                            attrs: {
                              user: usr,
                              theme: "white",
                              clickable: true,
                            },
                            on: {
                              remove: _vm.handleRemoveManaged,
                              click: _vm.handleClickUser,
                            },
                          })
                        }),
                      ]
                    : _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingManaged,
                              expression: "loadingManaged",
                            },
                          ],
                          staticClass: "infobox",
                        },
                        [
                          _vm._v(
                            " This user is not currently managing any users. "
                          ),
                        ]
                      ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }