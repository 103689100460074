<template>
    <div v-loading="loading" class="app-container">
        <el-row v-if="!loading && user" :gutter="20">
            <el-col :span="8" class="user-panel">
                <h3>External User Profile</h3>
                <user-profile show-account-email :admin="true" :user="user" />
            </el-col>
            <el-col :span="16" class="plan-panel">
                <h3>Managed Users</h3>

                <template
                    v-if="
                        !loadingManaged && managedUsers && managedUsers.length
                    "
                >
                    <el-input
                        v-model="managedFilter"
                        :suffix-icon="managedFilter ? '' : 'el-icon-search'"
                        clearable
                    ></el-input>
                    <div
                        v-if="
                            managedFilter && filtered_managed_users.length === 0
                        "
                        class="no-results"
                    >
                        No matches found
                    </div>
                    <user-card
                        v-for="usr in filtered_managed_users"
                        :key="usr.name"
                        :user="usr"
                        theme="white"
                        :clickable="true"
                        @remove="handleRemoveManaged"
                        @click="handleClickUser"
                    />
                </template>
                <div v-else v-loading="loadingManaged" class="infobox">
                    This user is not currently managing any users.
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import UserProfile from '../client/UserProfile.vue';
import UserCard from '../client/UserCard.vue';

export default {
    name: 'new-user',
    components: {UserProfile, UserCard},
    data() {
        return {
            user: {
                ndis_number: null,
                name: null,
                code: null,
                mobile: null,
                phone: null,
                dob: null,
                address: null,
                contact_email: null,
            },
            loading: true,
            loadingManaged: false,
            managedUsers: [],
            managedFilter: '',
        };
    },
    computed: {
        is_valid_user() {
            if (!this.user) return false;
            return this.user.id !== null;
        },
        filtered_managed_users() {
            if (this.managedFilter) {
                return this.managedUsers.filter((u) =>
                    u.name
                        .toLowerCase()
                        .includes(this.managedFilter.toLowerCase())
                );
            }
            return this.managedUsers;
        },
    },
    watch: {
        $route: function (val) {
            this.bindUser();
        },
    },
    mounted() {
        this.bindUser();
        this.getManagedUsers();
    },
    methods: {
        async bindUser() {
            if (this.$route.params.user_id !== undefined) {
                await this.$bind(
                    'user',
                    this.$fire.doc(`users/${this.$route.params.user_id}`),
                    {maxRefDepth: 1}
                )
                    .then((user) => {
                        if (user === null) {
                            // user not found, redirect to user list
                            this.$router.push('/users');
                        }
                        this.getManagedUsers();
                        this.loading = false;
                    })
                    .catch((e) => {
                        console.log('User error: ', e);

                        this.$notify.error({
                            title: 'Error',
                            message: 'Unable to load user profile',
                        });
                        this.loading = false;

                        Sentry.captureException(e);
                    });
            } else {
                this.user = {
                    id: null,
                    ndis_number: null,
                    name: null,
                    code: null,
                    mobile: null,
                    phone: null,
                    dob: null,
                    address: null,
                    contact_email: null,
                };
                this.loading = false;
            }
        },
        async getManagedUsers() {
            if (this.user.managedUsers) {
                this.loadingManaged = true;
                this.managedUsers = [];

                for (const u of this.user.managedUsers) {
                    const userSnapshot = await this.$fire
                        .doc(`users/${u.id}`)
                        .get();
                    const user = userSnapshot.data();
                    user.id = userSnapshot.id;
                    this.managedUsers.push(user);
                }

                this.loadingManaged = false;
            }
        },
        handleRemoveManaged(managedId) {
            this.$confirm("Stop managing this user's account?", 'Remove user')
                .then(() => {
                    this.loadingManaged = true;
                    if (this.removeUser(this.user.id, managedId)) {
                        // remove user from managed list
                        const index = this.user.managedUsers.findIndex(
                            (u) => u.id === managedId
                        );
                        if (index >= 0) {
                            this.user.managedUsers.splice(index, 1);
                        }
                        this.getManagedUsers();
                    }
                })
                .catch(() => {
                    this.loadingManaged = false;
                });
        },
        handleClickUser(user) {
            this.$router.push(`/user/${user.id}`);
        },
        async removeUser(managingUserID, managedUserID) {
            const managingUserSnapshot = await this.$fire
                .doc(`/users/${managingUserID}`)
                .get();
            const managedUserSnapshot = await this.$fire
                .doc(`/users/${managedUserID}`)
                .get();

            let managingUser = managingUserSnapshot.data();
            let managedUser = managedUserSnapshot.data();

            // remove managedUser's ID from managingUser's managedUsers list
            if (managingUser.managedUsers) {
                managingUser.managedUsers = managingUser.managedUsers.filter(
                    (u) => u.id != managedUserSnapshot.id
                );
            }

            // remove managingUser's ID from managedUser's managedBy list
            if (managedUser.managedBy) {
                managedUser.managedBy = managedUser.managedBy.filter(
                    (u) => u.id != managingUserSnapshot.id
                );
            }

            try {
                // perform both updates in one transaction
                await this.$fire.runTransaction(async (transaction) => {
                    await transaction.update(managingUserSnapshot.ref, {
                        managedUsers: managingUser.managedUsers,
                    });
                    await transaction.update(managedUserSnapshot.ref, {
                        managedBy: managedUser.managedBy,
                    });
                });
                return true;
            } catch (e) {
                console.log(e);
                this.$notify.error({
                    title: 'Error',
                    message: 'Error removing user',
                });
                return false;
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
    padding: 0;
    min-height: calc(100vh - 50px);
    overflow: hidden;

    .el-row {
        margin: 0 !important;
    }
}

.user-panel {
    padding: 10px 40px 50px 40px !important;
    box-sizing: border-box;
    background-color: white;
    height: calc(100vh - 50px);
    overflow: scroll;
}

.plan-panel {
    padding: 0 40px 10px 40px !important;
    box-sizing: border-box;
    background-color: #f0f2f5;
    height: calc(100vh - 50px);
    overflow: scroll;
}

.infobox {
    background: white;
    padding: 50px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
}
</style>
